<template>
  <div class="row" v-if="SHOW_HTML" :key="SHOW_HTML">
    <div class="col-lg-8">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
        <div class="iq-card-body">
          <div class="image-block">
            <img v-if="quizDetail && quizDetail.app_image" :src="quizDetail.app_image" class="img-fluid rounded w-100" alt="blog-img"/>
          </div>
          <div class="blog-description mt-2" >
            <h5 class="mb-3 pb-3 border-bottom">
              {{quizDetail && quizDetail.app_name ? quizDetail && quizDetail.app_name : ""}}
            </h5>
            <div class="row blog-meta d-flex mb-3">
              <div class="col-12">
                <b-btn class="right_button mr-3 mb-1" variant="primary" size="sm" @click="registerForCounseling()">
                  Enroll Now
                </b-btn>
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3 mb-1" variant="primary" size="sm" @click="spcmhDashboard()">
                  Counseling Dashboard
                </b-btn>
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="right_button mr-3" variant="primary" size="sm" @click="spcmMyCounselingEdit()">
                  Edit
                </b-btn>
              </div>
            </div>
            <p v-if="quizDetail && quizDetail.app_description" class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description">
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Upcomming events -->
    <div class="col-lg-4 gide_right_mobile_data">
      <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
      <CareerHomeList v-if="!isMobileDevice"/>
    </div>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import { Apps } from "../../../FackApi/api/apps.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import { Spcms } from "../../../FackApi/api/Spcm.js"

export default {
  name: "SpcmProduct",
  components: {
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      apiName: "spcm_counseling_product",
      cvTitle: "My counseling",
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "My Counseling",
      quizDetail: null,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      quizId: "APPSPCMPRODUCTID",
      SHOW_HTML: false,
      registerUrl: `${process.env.VUE_APP_SERVER_URL}/contactUsV2?source=GIDEApp&module_id=EVERegPremiumAdmCounsId&module_name=Premium%20Admission%20Counseling`
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Find if the user is part of the spcm counseling
    const spcmResp = await Spcms.spcmView(this)
    if (spcmResp.resp_status && spcmResp.resp_data.count > 0) {
      // User found in SPCM take the user to the notes section
      this.$router.push("/spcm")
    }
    else {
      this.SHOW_HTML = true
    }
  },
  mounted () {
    socialvue.index()
    this.appView()
  },
  methods: {
    /**
     * spcmMyCounselingEdit
     */
    spcmMyCounselingEdit () {
      window.open(`quiz_edit/${this.quizId}`, "_blank")
    },
    /**
     * spcmhDashboard
     */
    async spcmhDashboard () {
      this.$router.push("/spcm_counseling_dashboard")
    },
    /**
     * Get Info
     */
    async appView () {
      try {
        const quizResp = await Apps.appView(this, this.quizId)
        this.quizDetail = quizResp.resp_data.data
      }
      catch (error) {
        console.error("Exception in appview()", error.message)
      }
    },
    /**
     * Register For Counseling
     */
    registerForCounseling () {
      window.open(this.registerUrl, "_blank")
    }
  }
}
</script>
<style>
@media (min-width: 576px){
  .right_button{
    float: right;
  }
}
</style>
